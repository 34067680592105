//= ../../node_modules/choices.js/public/assets/scripts/choices.min.js
//= ../../node_modules/moment/min/moment.min.js
//= ../../node_modules/daterangepicker/daterangepicker.js

let filterChoices = [];

(function() {
  $('.calendar__filter select').each(function(_, item) {

  new Choices(item, {
      searchEnabled: false,
    });

    
  });

  var minDate = moment().startOf('day').add(1, 'day');
  var startDate = moment().startOf('day').add(7, 'day');

  var locale = {
    format: 'DD.MM',
    daysOfWeek: [
      'вс',
      'пн',
      'вт',
      'ср',
      'чт',
      'пт',
      'сб'
    ],
    monthNames: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    firstDay: 1,  
  };

  $('#dates').daterangepicker({
    autoApply: true,
    opens: 'center',
    minDate: minDate,
    startDate: startDate,
    endDate: moment(startDate).add(14, 'day'),
    locale: window.calendarLocale || locale,
  });

  var dates = $('#dates').data('daterangepicker');

  

  $('.filter select').each(function(_, item) {
    
    let config = {
      searchEnabled: false,
      searchChoices: false,
    }

    const parent = item.parentElement 

    if(item.classList.contains('renderSelectedChoicesAlways')) {
        config.renderSelectedChoices = 'always'
        parent.classList.add('renderSelectedChoicesAlways')
    }

    let choice = new Choices(item, config);

    filterChoices.push({
      key : item.name, 
      choice
    });

    if(config.renderSelectedChoices !== 'always') return 


    parent.addEventListener('click', e => {
        if(parent.children[0].classList.contains('is-open')){
          choice.hideDropdown()
        }
    })

    item.addEventListener(
      'choice',
      function(event) {
        

        const element = event.detail.choice

        element.customProperties.prev = 
          element.customProperties.prev === undefined ? !element.selected : !element.customProperties.prev

        setTimeout(e => {
          const values = choice.config.choices
          values.find(x => x.value === element.value).selected = element.customProperties.prev
          choice.clearStore()
          choice.setChoices(
            values,
            'value',
            'label',
            true,
          )
        })
      },
      false
    );

  });

  /*
  document.querySelectorAll('.selectbox').forEach(item => {
      item.addEventListener('click', e => {
        if(!e.target.classList.contains('renderSelectedChoicesAlways')) return; 

        console.log('click click');
      })    
  })
  */

  $('#calendar').submitForm({
    responseElement: $('.calendar__items-body'),
    beforeSend: function() {
      if ($(window).width() <= 768) {
        $('.calendar__filters-container').slideUp();
      }

      return true;
    },
    prepare: function(data) {
      delete data.dates;

      data.dateFrom = dates.startDate.format('YYYY-MM-DD');
      data.dateTo = dates.endDate.format('YYYY-MM-DD');

      return data;
    },
  });  

  $('#filter').submitForm({ ajax: false }).on('change', e => filterTourLoadData(false));

  $(document).on('click', '.tours__clndr-number', function(e){
      let date = $(this).attr('href')
      date = date.split('date=')[1]
      console.log(date);
      filterTourLoadData(date)

      $('.tours__clndr-number').removeClass('tours__clndr-number_active')
      $(this).addClass('tours__clndr-number_active')

      e.preventDefault()
      return false
  })

  filterTourCurrentFilters()

  /*
  $('#filter').on('submit', function(e){
      console.log(e);
      e.preventDefault();
      return false; 
  })
  */

  $('#filter input[type=submit]').on('click', function() {
    if ($(window).width() < 1200) {
      $('#dates').attr('disabled', true);

      $('#dateFrom')
        .removeAttr('disabled')
        .val(dates.startDate.format('YYYY-MM-DD'));
      $('#dateTo')
        .removeAttr('disabled')
        .val(dates.endDate.format('YYYY-MM-DD'));
    }
  });

  $('#filter input[type=reset]').on('click', function() {
    filterChoices.forEach(function(item) {
      item.choice.setChoiceByValue('');
      item.choice.removeActiveItems();
      item.choice.removeHighlightedItems();
    });

    $('#dateFrom').val('');
    $('#dateTo').val('');
    
    $('#filter').submit();

    filterTourLoadData(); 

  });  

  //
  // mobile
  //

  $('.calendar__filters-mobile').on('click', function(){
    $('.calendar__filters-container').slideDown();
  });

  $('.calendar__filters-close').on('click', function(){
    $('.calendar__filters-container').slideUp();
  });

  $('.filter__mobile').on('click', function(){
    $('.filter__wrap').slideDown();
  });

  $('.filter__close, .button-filter-close').on('click', function(){
    $('.filter__wrap').slideUp();
  });
})();


function filterTourLoadData(_date = false){
    const data = $('#filter').serialize()
    const $container = $('#tours__items')
    
    let url = new URL(location.href)
    let params = new URLSearchParams(data)
    let date = !!_date ? _date : url.searchParams.get('date')
    if(date == null) date = ''

    params.set('date', date)
    
    url = url.pathname + '?' + params.toString()

    history.pushState(null, null, url);

    $container.addClass('processing')

    $.post(url, data => {
        $container.html(data).removeClass('processing')
        filterTourCurrentFilters()
    })
}

function filterTourCurrentFilters(){
   const $container = $('#tours__items-filters')
   const $filter = $('#filter')

   let url = new URL(location.href)
   let keys = url.searchParams.keys()
   
   let filters = []
   
   console.log('filterTourCurrentFilters');


   for( let key of keys ) {
        if(['cityFrom','date'].includes(key)) continue   
      
        let values = url.searchParams.getAll(key)

        values.forEach( value => {
            if(!value) return 
            let title = $('select[name="'+key+'"]').children(`option[value="${value}"]`).text()
            let filter = `<span data-key="${key}" data-value="${value}">${title}</span>`
            if(filters.includes(filter)) return

            filters.push(filter)
        })
   }  

   filters = filters.length ? filters.join('') : ''
   $container.html(filters)
   
   $container.find('span').on('click', function(){
     let key = $(this).data('key')
     const el = filterChoices.find( x => x.key == key)
     
     

     if(key.endsWith('[]')){
        let removeValue = $(this).data('value')

        let values =  url.searchParams.getAll(key).filter( x => x != removeValue)
        
        el.choice.removeActiveItems()
        el.choice.removeHighlightedItems()
        el.choice.setChoiceByValue(values)
        
        url.searchParams.delete(key)
        values.length ? values.forEach( val => url.searchParams.append(key, val)) : null

     }  
     else{
        url.searchParams.delete(key)
        
        el.choice.setChoiceByValue('')
        el.choice.removeActiveItems()
        el.choice.removeHighlightedItems()
     }

     url = location.pathname + '?' + url.searchParams.toString()
     history.pushState(null, null, url);
    
     console.log(url);

     setTimeout(x => {
        filterTourLoadData(false)
     }, 100) 
   })

}